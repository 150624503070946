import { fromLonLat } from 'ol/proj';

/**
 * calculate left padding of map based on screen size
 * @param {*} vueInstance
 */
export function calculateShift(vueInstance) {
  const screenWidth = vueInstance.$vuetify.breakpoint.width;
  if (vueInstance.$vuetify.breakpoint.smAndDown) {
    // no shift
    return 0;
  }
  if (screenWidth < 1200) {
    return 670;
  }
  if (screenWidth < 1500) {
    return 750;
  }
  return 880;
}

export const noeExtent = [
  ...fromLonLat([14.4589943806031602, 47.4221842654084398]),
  ...fromLonLat([17.0688232694799744, 49.0204561331672863])
];
